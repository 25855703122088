import React from "react";

import StatsComponent from "./components/StatsComponent";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

import {
  separateThousandsMonetary,
  separateThousandsMonetaryEuros,
} from "../../utils/separateThousand";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import {
  getPaiementsLastWeek,
  getPaiementsStats,
  getPaiementsToday,
  getPaiementsWeek,
  getStatsPaiement,
} from "../../api/paiementsApi";
import { getClientsStats, getClientsToday } from "../../api/clientsApi";
import { useState } from "react";
import StatsPaiements from "./components/StatsPaiements";
import { getDataHeader } from "../../api/statsApi";
import Loader from "../../components/shared/Loader";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col, Collapse, Row, Table, Tag } from "antd";
import {
  useGetClientsToday,
  useGetPaiementLastWeek,
  useGetPaiementsToday,
  useGetPaiementsWeek,
  useGetStatsHeader,
  useGetStatsPaiement,
  useGetSubscriptionMonth,
} from "../../datas/hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const currentUser = useSelector(selectCurrentUser);

  const [dataEuro, setDataEuro] = useState([]);
  const [dataCfa, setDataCfa] = useState([]);
  const [dataGnf, setDataGnf] = useState([]);
  const [countDevise, setCountDevise] = useState([]);
  const [statsHeader, setStatsHeader] = useState([]);
  const [loading, setLoading] = useState(false);

  const [todayData, setTodayData] = useState();
  const [todayPaiement, setTodayPaiement] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const [clientStat, setClientStat] = useState([]);
  const [paiementsWeekStat, setPaiementsWeekStat] = useState([]);
  const [lastWeekPaiement, setLastWeekPaiement] = useState([]);

  const chartHeight = window.innerWidth < 600 ? 250 : 150;

  const { data: paiementStats, isLoading: loadingPaiementStats } =
    useGetStatsPaiement(currentUser.id);

  useEffect(() => {
    if (paiementStats) {
      setDataEuro(paiementStats?.euro);
      setDataCfa(paiementStats?.cfa);
      setDataGnf(paiementStats?.gnf);
    }
    let count = [];
    count = [
      {
        id: 1,
        name: "Paiements en Euro",
        stat: paiementStats?.totalEuro,
        currency: "EUR",
      },
      {
        id: 2,
        name: "Paiements en GNF",
        stat: paiementStats?.totalGnf,
        currency: "GNF",
      },
      {
        id: 3,
        name: "Paiements en CFA",
        stat: paiementStats?.totalCfa,
        currency: "CFA",
      },
    ];
    setCountDevise(count);
  }, [paiementStats]);

  const { data: statsHeaderData, isLoading: loadingStatsHeader } =
    useGetStatsHeader(currentUser.id);

  const { data: clientsToday, isLoading: loadingClientsToday } =
    useGetClientsToday();

  const { data: paiementsToday, isLoading: loadingPaiementsToday } =
    useGetPaiementsToday();

  const getStatsHead = async () => {
    await getDataHeader(currentUser.id)
      .then((res) => {
        setStatsHeader(res.data);

        res.data.forEach((item, index) => {
          console.log("item", item);
        });
      })
      .catch((err) => console.log("err", err));

    await getClientsToday().then((res) => {
      setTodayData(res.data);
    });
    await getPaiementsToday().then((res) => {
      setTodayPaiement(res.data);
    });
  };

  const { data: paiementsWeekData, isLoading: loadingPaiementsWeek } =
    useGetPaiementsWeek();

  const { data: paiementsLastWeekData, isLoading: loadingPaiementsLastWeek } =
    useGetPaiementLastWeek();

  const labelPaiemenLastWeek = paiementsLastWeekData?.data.map(
    (data) => data.date
  );

  const labelsEuro = dataEuro?.map((data) => data.date);
  const labelsCfa = dataCfa?.map((data) => data.date);
  const labelsGnf = dataGnf?.map((data) => data.date);

  const dataPointsPaiementLastWeek = paiementsLastWeekData?.data?.map(
    (data) => data.count
  );

  const dataPointsEuro = dataEuro?.map((data) => data.total);
  const dataPointsCfa = dataCfa?.map((data) => data.total);
  const dataPointsGnf = dataGnf?.map((data) => data.total);

  const chartPaiementLastWeek = {
    chart: {
      type: "column",
    },
    title: {
      text: "Paiements 7 Last days",
    },
    xAxis: {
      categories: labelPaiemenLastWeek,
      reversed: false,
    },
    yAxis: {
      reversed: false,
    },
    series: [
      {
        name: "Paiements",
        data: dataPointsPaiementLastWeek,
        color: "rgb(238, 89, 15)",
      },
    ],
  };

  console.log("currentUser", currentUser);

  const chartDataEuro = {
    title: {
      text: "Euros",
    },
    xAxis: {
      categories: labelsEuro,
    },
    series: [
      {
        name: "Euros",
        data: dataPointsEuro,
        color: "rgb(13, 135, 135)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const chartDataCfa = {
    title: {
      text: "CFA",
    },
    xAxis: {
      categories: labelsCfa,
    },
    series: [
      {
        name: "CFA",
        data: dataPointsCfa,
        color: "rgb(217, 169, 58)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const chartDataGnf = {
    title: {
      text: "GNF",
    },
    xAxis: {
      categories: labelsGnf,
    },
    series: [
      {
        name: "GNF",
        data: dataPointsGnf,
        color: "rgb(215, 30, 89)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const columnsOrange = [
    {
      title: "Transaction en GNF",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Montant",
          dataIndex: "total",
          key: "total",
          render: (text) => {
            return (
              <Tag color="orange-inverse">
                {separateThousandsMonetary(text) + " GNF"}
              </Tag>
            );
          },
        },
      ],
    },
  ];

  const columnsCFA = [
    {
      title: "Transaction en CFA",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Montant",
          dataIndex: "total",
          key: "total",
          render: (text) => {
            return (
              <Tag color="green-inverse">
                {separateThousandsMonetary(text) + " CFA"}
              </Tag>
            );
          },
        },
      ],
    },
  ];

  const columnsEuro = [
    {
      title: "Transaction en Euro",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Montant",
          dataIndex: "total",
          key: "total",
          render: (text) => {
            return (
              <Tag color="blue-inverse" className="">
                {separateThousandsMonetaryEuros(text) + " €"}
              </Tag>
            );
          },
        },
      ],
    },
  ];

  return (
    <TemplateHeaderContainter
      title={`Welcome ${currentUser.firstName}`}
      description=""
      id={currentUser.id}
    >
      <StatsComponent
        stats={statsHeaderData}
        todayData={clientsToday}
        todayPaiement={paiementsToday}
        currentUser={currentUser}
        loading={
          loadingStatsHeader ||
          loadingPaiementsWeek ||
          loadingClientsToday ||
          loadingPaiementsToday
        }
        title={"Total des Utilisateurs"}
        colorIcon={"text-orange-500"}
      />
      <div className="p-2 mt-5">
        <p className="font-light text-orange-500 italic text-xs ml-0">
          {" "}
          si le mois n'apparaît pas cela veut dire que le montant est a 0
        </p>

        <Row gutter={12}>
          <Col sm={24} lg={8}>
            <Table
              scroll={{
                y: 190,
              }}
              columns={columnsEuro}
              dataSource={dataEuro}
              size="small"
              className="shadow-md"
            />
          </Col>

          <Col sm={24} lg={8}>
            <Table
              scroll={{
                y: 190,
              }}
              columns={columnsOrange}
              dataSource={dataGnf}
              size="small"
              className="shadow-md"
            />
          </Col>

          <Col sm={24} lg={8}>
            <Table
              scroll={{
                y: 190,
              }}
              columns={columnsCFA}
              dataSource={dataCfa}
              size="small"
              className="shadow-md"
            />
          </Col>
        </Row>
        {/* <RevenueCollapse
          loading={loading}
          dataEuro={dataEuro}
          dataGnf={dataGnf}
          dataCfa={dataCfa}
          separateThousandsMonetary={separateThousandsMonetary}
          separateThousandsMonetaryEuros={separateThousandsMonetaryEuros}
        /> */}
      </div>
      <StatsPaiements
        stats={countDevise}
        title={"Total des Paiements"}
        colorIcon={"text-cyan-600"}
      />

      <div id="paiement" className="mt-0 px-5 py-3 lg:m-2 rounded-lg shadow-md">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-90 ml-5">
              Statistiques des Paiements
            </h3>
            <div className="grid grid-rows md:grid-cols-1 lg:grid-cols-2">
              <div
                id="paiementGNF"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2  border-red-500 shadow-red-400/50 lg:first:col-span-1"
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartDataGnf}
                />
              </div>
              <div
                id="paiementEuro"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-teal-500"
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartDataEuro}
                />
              </div>

              <div
                id="paiementCFA"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartDataCfa}
                />
              </div>
              {currentUser.roles === "adnin" && (
                <div
                  id="paiementsStat"
                  className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartPaiementLastWeek}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </TemplateHeaderContainter>
  );
}
