import React, { useState, useEffect } from "react";
import TitleComponent from "../../shared/TitleComponent";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getAllCategories } from "../../api/categoriesApi";
import { addPublication } from "../../api/publicationApi";
import { getCountriesApi } from "../../api/utilsApi";
import Loader from "../../components/shared/Loader";
import { getAllType } from "../../api/typesApi";
import { getAllUsers } from "../../api/usersApi";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { add, forEach, set } from "lodash";
import { data } from "autoprefixer";
import { addCustomPlanApi } from "../../api/custonPlanApi";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useSelector } from "react-redux";

const AddPublicationPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  console.log("currentUser", currentUser);

  const history = useHistory();
  const [form, setForm] = useState({
    formule: "payant",
    title: "",
    description: "",
    language: "",
    quality: "",
    rate_cfa: null,
    rate_eur: null,
    rate_gn: null,
    days: 3,
    release_date: "",
    trailer_link: "https://www.youtube.com/watch?v=JgHfx2v9zOU",
    adminId: null,
    categoryId: 1,
    type: [],
    file: "",
  });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [categorieOption, setCategorieOption] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [customPlans, setCustomPlans] = useState([]);

  const [langueOptions, setlangueOptions] = useState([
    { label: "Pular", value: "Pular" },
    { label: "Malinké", value: "Malinké" },
    { label: "Soussou", value: "Soussou" },
    { label: "Wolof", value: "Wolof" },
    { label: "Français", value: "Français" },
    { label: "Anglais", value: "Anglais" },
  ]);

  const [qualityOption, setQualityOption] = useState([
    { label: "4K", value: "4K" },
    { label: "Full HD", value: "Full HD" },
    { label: "HD", value: "HD" },
    { label: "Low", value: "Low" },
  ]);

  const updateField = async (item, value) => {
    setForm({
      ...form,
      [item]: value,
    });
  };

  const onInputChange = (item, value) => {
    let data;
    if (value.length == undefined) {
      data = value.value;
    } else {
      let temp = [];
      value.forEach((el) => {
        temp.push(el.value);
      });
      data = temp;
    }
    setForm({
      ...form,
      [item]: data,
    });
  };

  const submit = async (e) => {
    console.log("🔴form", form);

    e.preventDefault();
    // setLoading(true);
    const formData = new FormData();
    formData.append("availability", false);
    formData.append("categoryId", form.categoryId);
    formData.append("description", form.description);
    formData.append("language", form.language);
    formData.append("quality", form.quality);
    formData.append("rate_cfa", Number(form.rate_cfa));
    formData.append("rate_eur", Number(form.rate_eur));
    formData.append("rate_gn", Number(form.rate_gn));
    formData.append("days", form.availability ? form.days : 730);
    formData.append("release_date", form.release_date);
    formData.append("title", form.title);
    formData.append("formule", form.formule);
    formData.append("trailer_link", form.trailer_link);
    formData.append("adminId", form.adminId);
    formData.append("file", file);

    await addPublication(formData)
      .then((res) => {
        console.log("res", res);

        customPlans.forEach((element) => {
          let data = {
            publicationId: res.data.data.id,
            days: form.days,
            country: element.country,
            devise: element.devise,
            price: element.price,
          };
          console.log("data", data);
          addCustomPlanApi(data)
            .then((res) => {
              console.log("res", res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        });

        setTimeout(() => {
          history.push(`${process.env.REACT_APP_CLIENT}/admin/contenus`);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    getCountries();
  }, []);

  useEffect(() => {
    let dataTemp = [];

    countries?.data?.map((c) => {
      dataTemp.push({
        country: c.name,
        devise: c.devise,
        price: null,
        days: form.days,
      });
    });

    console.log("dataTemp", dataTemp);

    setCustomPlans(dataTemp);
  }, [countries]);

  const getCountries = async () => {
    getCountriesApi().then((res) => {
      console.log("dataCountries", res.data.data);
      setCountries(res.data);
    });
  };

  const getData = async () => {
    let cate = await getAllCategories();
    let type = await getAllType();
    await getAllUsers().then((res) => {
      console.log("res.data", res.data.data);
      setUsers(res.data.data);
    });
    let catArray = [];
    let typeArray = [];

    cate.data.data.forEach((element) => {
      catArray.push({ label: element.label, value: element.id });
    });
    type.data.data.forEach((element) => {
      typeArray.push({ label: element.label, value: element.id });
    });
    setCategorieOption(catArray);
    setTypeOption(typeArray);
  };

  const getUsers = async () => {};

  useEffect(() => {
    setInterval(() => {
      localStorage.setItem("formulaire", JSON.stringify(form));
    }, 10000);
  }, [form]);

  // const addCustomPlan = (e) => {
  //   e.preventDefault();
  //   setCustomPlans([...customPlans, { country: "", rate: "" }]);
  // };

  // const updateCustomPlan = (index, field, value) => {
  //   const newCustomPlans = [...customPlans];
  //   newCustomPlans[index][field] = value;
  //   setCustomPlans(newCustomPlans);
  // };

  return (
    <TemplateHeaderContainter title="Création de contenus">
      {loading ? (
        <Loader />
      ) : (
        <div className=" -my-5 overflow-x-auto sm:-mx-6 lg:mx-8 ">
          <Link
            to={"/admin/contenus"}
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Retour aux contenus
          </Link>
          <div className="align-middle inline-block min-w-full sm:px-2 lg:px-2 ">
            <div className="lg:mx-2 mx-2 text-justify px-5 bg-white shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <form className="space-y-8   py-5">
                <div className="space-y-8 ">
                  <div>
                    <p className="mt-1 text-sm text-gray-500">
                      Des informations importantes sur la creation de contenus
                    </p>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 py-3">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Titre du contenu
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          onChange={(e) => {
                            updateField("title", e.target.value);
                          }}
                          value={form.title}
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="availability"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Producteur
                      </label>
                      <div className="mt-1">
                        <select
                          id="adminId"
                          name="adminId"
                          placeholder="choisir le producteur"
                          value={form.adminId}
                          onChange={(e) => {
                            updateField("adminId", e.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        >
                          <option> Choisir un Producteur</option>
                          {users.map(
                            (user) =>
                              user.roles === "producteur" && (
                                <option value={user.id}>
                                  {user.display_name}
                                </option>
                              )
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          onChange={(e) => {
                            updateField("description", e.target.value);
                          }}
                          value={form.description}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        />
                      </div>
                      <p className="mt-5 text-sm text-gray-500">
                        Information sur la description
                      </p>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="categorie"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Catégorie
                      </label>
                      <div className="mt-1">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={categorieOption[0]}
                          isClearable={true}
                          isSearchable={true}
                          options={categorieOption}
                          onChange={(choice) =>
                            onInputChange("categoryId", choice)
                          }
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type de contenus ( selection multiple possible )
                      </label>
                      <div className="mt-1">
                        <Select
                          // isMulti
                          name="colors"
                          options={typeOption}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(choice) => onInputChange("type", choice)}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="langue"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Langue si non disponible merci d'ajouter
                      </label>
                      <div className="mt-1">
                        <CreatableSelect
                          isMulti
                          options={langueOptions}
                          onChange={(choice) =>
                            onInputChange("language", choice)
                          }
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Qualite
                      </label>
                      <div className="mt-1">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={qualityOption[0]}
                          isClearable={true}
                          options={qualityOption}
                          onChange={(choice) =>
                            onInputChange("quality", choice)
                          }
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="link"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Lien de la bande annonce (Un lien youtube)
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="link"
                          id="link"
                          onChange={(e) => {
                            updateField("trailer_link", e.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cover photo (precisant la limite de taille)
                      </label>
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-2 pb-2">
                        <label className="mt-5 flex items-center justify-center flex-col hover:bg-gray-100 hover:border-gray-300">
                          <div className="relative flex flex-col items-center justify-center ">
                            {file ? (
                              <div>
                                <img
                                  style={{
                                    width: "200px",
                                    height: "300px",
                                  }}
                                  className="object-cover"
                                  src={URL.createObjectURL(file)}
                                  alt={file}
                                />
                              </div>
                            ) : (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    // fill-rule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    // clip-rule="evenodd"
                                  />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  cover image
                                </p>
                              </>
                            )}
                          </div>
                          <input
                            type="file"
                            className="opacity-0"
                            accept="image/*"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="date_s"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date de sortie
                      </label>
                      <div className="mt-1">
                        <input
                          type="date"
                          name="date_s"
                          id="date_s"
                          onChange={(e) => {
                            updateField("release_date", e.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="formule"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Formule
                      </label>
                      <div className="mt-1">
                        <select
                          id="formule"
                          name="formule"
                          defaultValue={form.formule}
                          onChange={(e) => {
                            updateField("formule", e.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                        >
                          <option value={"payant"}>Payante</option>
                          <option value={"gratuit"}>Gratuit</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {console.log("form.formule", form.formule)}
                <div className="grid grid-cols-2 lg:grid-cols-8 gap-2">
                  {form.formule === "payant" ? (
                    <>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="prix_gn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix en GNF
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.rate_gn}
                            type="number"
                            name="prix_gn"
                            id="prix_gn"
                            onChange={(e) => {
                              updateField("rate_gn", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="prix_cfa"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix en CFA
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.rate_cfa}
                            type="number"
                            name="prix_cfa"
                            id="prix_cfa"
                            onChange={(e) => {
                              updateField("rate_cfa", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="prix_eur"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix en EUR
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.rate_eur}
                            type="number"
                            name="prix_eur"
                            id="prix_eur"
                            onChange={(e) => {
                              updateField("rate_eur", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="availability"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nombre de jours
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.days}
                            type="number"
                            name="days_s"
                            id="days"
                            onChange={(e) => {
                              updateField("days", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {/* <div className="mt-10">
                  <button
                    onClick={addCustomPlan}
                    className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Ajouter un plan
                  </button>
                  <div className="grid grid-cols-6 lg:grid-cols-8 gap-4 mt-4 ">
                    {form.formule === "payant"
                      ? customPlans.map((plan, index) => (
                          <>
                            <div className="col-span-2 lg:col-span-3">
                              <label
                                htmlFor="prix_gn"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Pays
                              </label>
                              <div className="mt-1">
                                <select
                                  className="basic-single w-full"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  isSearchable={true}
                                  value={plan.country}
                                  onChange={(e) => {
                                    updateCustomPlan(
                                      index,
                                      "country",
                                      e.target.value
                                    );
                                  }}
                                >
                                  {countries &&
                                    countries?.data?.map((country) => (
                                      <option
                                        key={country.name}
                                        value={country.name}
                                      >
                                        {country.flag + " " + country.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-span-2 lg:col-span-3">
                              <label
                                htmlFor="prix_cfa"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Prix{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number"
                                  name="price"
                                  id="price"
                                  onChange={(e) => {
                                    updateCustomPlan(
                                      index,
                                      "price",
                                      e.target.value
                                    );
                                  }}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <button
                              onClick={() => {
                                customPlans.splice(index, 1);
                                setCustomPlans([...customPlans]);
                              }}
                              className="bg-red-500 mt-8 hover:bg-red-700 text-white font-bold h-8 justify-center items-center py-1 px-4 rounded"
                            >
                              X
                            </button>
                          </>
                        ))
                      : null}
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("customPlans", customPlans);
                    }}
                    className="bg-teal-500 hover:bg-teal-700 text-white font-bold mt-5 py-2 px-4 rounded"
                  >
                    valider
                  </button>
                </div> */}

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={submit}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </TemplateHeaderContainter>
  );
};

export default AddPublicationPage;
