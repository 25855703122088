import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  faArrowDown,
  faArrowRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import { getAllPublications } from "../../api/publicationApi";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
// import { ModalUpdateuser } from "../../components/Modals/ModalUpdateUser";
import Loader from "../../components/shared/Loader";

import moment from "moment";
moment.locale("fr");

import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalActivate from "../../components/Modals/ModalActivate";
import PaiementTable from "../../components/shared/PaiementTable";
import { ToastContainer, toast } from "react-toastify";

import { getPaiementByAdmin, getStatsPaiement } from "../../api/paiementsApi";
import { getUserInfo, sendMailResume } from "../../api/usersApi";
import {
  separateThousandsMonetary,
  separateThousandsMonetaryEuros,
} from "../../utils/separateThousand";
import { FaDollarSign, FaRadiation } from "react-icons/fa";
import RevenuTable from "../../components/TableComponent/RevenuTable";
import TablePaiements from "../../components/TableComponent/TablePaiements";
import { message } from "antd";
import { useSelector } from "react-redux";

const DetailUser = () => {
  let history = useHistory();
  let { id } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);
  const userConnected = useSelector((state) => state.auth.user);

  console.log("userConnected", userConnected);

  const [user, setUser] = useState({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    status: "",
    createdAt: "",
  });

  console.log("user", user);

  const [openDelete, setOpenDelete] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paiements, setPaiements] = useState([]);
  const [movies, setMovies] = useState([]);
  const [totalData, setTotalData] = useState();
  const [dataEuro, setDataEuro] = useState([]);
  const [dataCfa, setDataCfa] = useState([]);
  const [dataGnf, setDataGnf] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);

  const [formUpdate, setFormUpdate] = useState({
    id: user?.id,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    phone: user?.phone,
    status: user?.status,
  });

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  function handleUpdateuser(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  const getAdminInfo = async () => {
    try {
      await getUserInfo(id)
        .then((res) => {
          console.log("res", res.data.data);
          setUser(res.data.data);
          getPaiementByAdmin(id).then((res) => {
            setPaiements(res.data.data);
            setTotalData(res.data.total);
            console.log("res.data Paiement :>> ", res.data.data);
          });
        })
        .catch((err) => {
          console.log(err);
          // history.push("/admin/not-found");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGraph = async () => {
    let count = [];
    setLoading(true);
    await getStatsPaiement(id)
      .then((res) => {
        setDataEuro(res.data.euro);
        setDataCfa(res.data.cfa);
        setDataGnf(res.data.gnf);

        count = [
          {
            id: 1,
            name: "Paiements en Euro",
            stat: res.data.totalEuro,
            currency: "EUR",
          },
          {
            id: 2,
            name: "Paiements en GNF",
            stat: res.data.totalGnf,
            currency: "GNF",
          },
          {
            id: 3,
            name: "Paiements en CFA",
            stat: res.data.totalCfa,
            currency: "CFA",
          },
        ];
        setCountDevise(count);
        setLoading(false);
      })
      .catch((err) => console.log("err", err));
  };

  function toggleCollapse() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    getAdminInfo();
    getDataMovies();
    getGraph();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    await deleteDealer(user?.id)
      .then(() => {
        setOpenDelete(false);
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/users");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const onUpdateUser = async (id) => {
    setLoading(true);
    await updateDealer(user?.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getuser();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  const getDataMovies = async () => {
    const data = await getAllPublications();
    setMovies(data.data.data);
    console.log(data.data.data);
    setLoading(false);
  };

  // Activate user for movie
  const onActivate = async () => {
    setLoading(true);
    await updateDealer(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        toast.success("🚀 Activation avec succès !!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };
  const sendMail = async () => {
    await sendMailResume(user.id)
      .then(() => {
        message.success("🚀 Facture envoyée avec succès !!");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <TemplateHeaderContainter
      title={`Compte de ${user?.firstName}`}
      className=""
    >
      <div className=" px-4 sm:px-6 md:px-8 flex justify-between">
        <Link
          to={"/admin/users"}
          type="button"
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Retour Liste des users
        </Link>

        {userConnected.roles === "admin" && (
          <button
            onClick={sendMail}
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Envoi facture
          </button>
        )}
      </div>
      {loading || !user || user === undefined ? (
        <Loader />
      ) : (
        <div className=" flex justify-center mx-2 lg:mx-10">
          <div className="w-full p-1">
            <div className="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
              <img className="rounded-t-lg" src="" alt="" />
              <div className="py-3 px-2 rounded-lg bg-white">
                <div className="flex justify-center mb-4 ">
                  <h1 className="text-gray-700 uppercase font-bold text-lg md:text-2xl hover:text-gray-900 ">
                    {user?.firstName + " " + user?.lastName}
                  </h1>
                  <span className="tracking-wide ml-3">
                    {user?.status === true ? (
                      <p className=" px-3 rounded-xl uppercase bg-green-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        Actif
                      </p>
                    ) : (
                      <p className=" px-3 rounded-xl uppercase bg-red-500 text-white font-normal text-sm w-24 h-7 flex justify-center pt-1">
                        bloqué
                      </p>
                    )}
                  </span>
                </div>
                <div className="mb-5 lg:flex grid gap-1 text-center">
                  <p className="text-md "> commission aprés taxe :</p>
                  <div className="text-sm w-44 text-center mx-auto font-light bg-slate-600 text-white px-2 py-0.5 rounded-full">
                    commission € : {user.commissionEUR} %
                  </div>
                  <div className="text-sm w-44 text-center mx-auto font-light bg-slate-600 text-white px-2 py-0.5 rounded-full">
                    {" "}
                    commission CFA : {user.commissionCFA} %
                  </div>
                  <div className="text-sm w-44 text-center mx-auto font-light bg-slate-600 text-white px-2 py-0.5 rounded-full">
                    {" "}
                    commission GNF : {user.commissionGNF} %
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 grid-cols-1 ml-5 gap-y-0 lg:gap-3 ">
                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Date Création :{" "}
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(user?.createdAt).format("DD MMM YYYY hh:mm")}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Téléphone :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {user?.phone}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      E-email :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {user?.email}
                    </span>
                  </div>

                  <div className="flex items-center">
                    <label className="text-current text-sm w-2/6 lg:w-2/6 ">
                      Mis à jour :
                    </label>
                    <span className="text-gray-700 tracking-wide">
                      {moment(user?.updatedAt).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-3">
                  <button
                    onClick={() => {
                      setOpenModalActive(true);
                    }}
                    className="ml-2 py-1.5 px-2 bg-green-400 text-gray-800 font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-green-500"
                  >
                    Activé
                  </button>
                  <button
                    onClick={() => {
                      setOpenUpdate(true);
                      setFormUpdate({
                        id: user?.id,
                        email: user?.email,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        phone: user?.phone,
                        status: user?.status,
                      });

                      // setIsEditable(!isEditable);
                      // setStatusEdit(false); // False = Add step
                    }}
                    className="ml-2 py-1.5 px-2 bg-yellow-400 text-gray-800 font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-yellow-500"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => setOpenDelete(true)}
                    className="ml-2 py-1.5 px-2 bg-red-400 text-white font-bold rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-red-500"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <span className="ml-2"> Supprimer</span>
                  </button>
                </div>
              </div>
              <div className="absolute -top-32 -right-5  md:top-4 md:right-4 py-2 px-2 bg-white rounded-lg">
                <span className="text-lg md:text-2xl"> </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="p-4">
        <h3
          className="text-lg font-medium mb-2 cursor-pointer"
          onClick={toggleCollapse}
        >
          <FontAwesomeIcon
            icon={isOpen ? faArrowDown : faArrowRight}
            className="text-orange-500 hover:text-orange-600 mr-5 font-bold h-6"
          />{" "}
          {isOpen ? "Masquer les Détails" : "Voir les Détails"}
        </h3>
        <div>
          <p className="font-bold text-red-500 italic text-sm ml-5">
            Les frais d'envoi ou de transfert de l'argent ne sont pas inclus
          </p>
          <div className={`collapse ${isOpen ? "" : "hidden"}`}>
            {dataCfa.length > 0 && (
              <div className="flex flex-col">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                  <RevenuTable
                    data={dataCfa}
                    user={user}
                    separate={separateThousandsMonetary}
                    title={"Revenus CFA"}
                    currency={"CFA"}
                    commision={user.commissionCFA}
                  />
                </div>
              </div>
            )}

            {dataGnf.length > 0 && (
              <div className="flex flex-col">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                  <RevenuTable
                    data={dataGnf}
                    user={user}
                    separate={separateThousandsMonetary}
                    title={"Revenus GNF"}
                    currency={"GNF"}
                    commision={user.commissionGNF}
                  />
                </div>
              </div>
            )}

            {dataEuro.length > 0 && (
              <div className="flex flex-col">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                  <RevenuTable
                    data={dataEuro}
                    user={user}
                    separate={separateThousandsMonetaryEuros}
                    title={"Revenus EUR"}
                    currency={"Eur"}
                    commision={user.commissionEUR}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {user.roles === "producteur" ? null : (
        <>
          <h1 className="text-gray-700 font-bold ml-3 mt-12 text-lg md:text-2xl mb-2 hover:text-gray-900 ">
            Mon Historique d'activation - {totalData}
          </h1>
          {loading ? (
            <Loader />
          ) : (
            <div className="mt-2 ml-2">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                      <PaiementTable paiements={paiements} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )} */}

      {user.roles === "producteur" ? null : (
        <TablePaiements
          open={open}
          setOpen={setOpen}
          paiements={paiements}
          setPaiements={setPaiements}
          loading={loading}
          allPaiements={paiements}
        />
      )}
      <GenericModalDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        message={"Etes-vous sûre de vouloir supprimer ?"}
        data={user?.firstName + " " + user?.lastName}
        onDelete={onDelete}
      />

      {/* Modal Update user */}
      {/* {user && (
        <ModalUpdateuser
          formUpdate={user}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateuser={handleUpdateuser}
          onUpdateuser={onUpdateuser}
        />
      )} */}

      {/* Modal Update user */}
      <ModalActivate
        formActive={formActive}
        open={openModalActive}
        setOpen={setOpenModalActive}
        onActivate={onActivate}
        data={form}
        movies={movies}
      />
      <ToastContainer />
    </TemplateHeaderContainter>
  );
};

export default DetailUser;
